<template>
	<div class="inline">
		<ClientOnly>
			<font-awesome-icon
				v-if="!loading"
				:icon="icon" />
		</ClientOnly>
	</div>
</template>

<script setup>
	//Use this component if you want an icon to be rendered on the page. This cannot be used in an v-if statement.
	//The ClientOnly wrapper is in place to prevent hydration issues.

	import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

	const { icon } = defineProps({ icon: String })

	//Wait for the page to load on the client side to render font-awesome icons
	const loading = ref(true)

	onMounted(() => {
		loading.value = false
	})
</script>

<style scoped></style>
