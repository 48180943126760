<template>
	<ModalGenericModal
		:title="titleText"
		container-class="max-w-sm errorPopupComponent"
		ref="modal"
		:hide-close-btn="hideCloseBtn"
		:click-outside-enabled="!hideCloseBtn">
		<div class="pt-6 pb-3 px-6 relative z-30 text-popup-text bg-popup-bg border-x-2 border-popup-border">
			<p v-html="bodyText"></p>
		</div>

		<div class="flex justify-center gap-8 py-4 bg-popup-bg border-b-2 border-x-2 rounded-b-lg border-popup-border">
			<ButtonModalClose
				:label="closeBtnText"
				@click="closeModal" />
			<ButtonModalAccept
				v-if="acceptCallback"
				:label="acceptBtnText"
				@click="acceptModal" />
		</div>
	</ModalGenericModal>
</template>

<script setup>
	import { useNuxtApp } from "#app"
	import { onMounted, onUnmounted } from "vue"
	import { useRefsStore } from "~/stores/RefsStore"
	import { HIDE_ERROR, SHOW_ERROR } from "assets/events/errors"
	import { useI18n } from "vue-i18n"
	import { CART_CLEARED } from "assets/events/cart.js"

	const { t } = useI18n()
	const { $eventBus } = useNuxtApp()
	const refsStore = useRefsStore()
	const modal = ref(null)

	const hideCloseBtn = ref(false)
	const bodyText = ref(t("errorPopupDefaultBody"))
	const titleText = ref(t("errorPopupDefaultTitle"))
	const closeBtnText = ref(t("ok"))
	const closeCallback = ref(null)
	const acceptBtnText = ref(t("yes"))
	const acceptCallback = ref(null)

	const openModal = () => refsStore.errorPopupRef.openModal()
	const closeModal = () => {
		closeCallback.value && typeof closeCallback.value === "function" && closeCallback.value()
		hideModal()
	}

	const hideModal = () => {
		refsStore.errorPopupRef.closeModal()
	}

	const acceptModal = () => {
		acceptCallback.value && typeof acceptCallback.value === "function" && acceptCallback.value()
		refsStore.errorPopupRef.closeModal()
	}

	onMounted(() => {
		refsStore.setErrorPopupRef(modal.value)

		$eventBus.$on(SHOW_ERROR, (options) => {
			hideCloseBtn.value = options && options.hideCloseBtn ? options.hideCloseBtn : false
			titleText.value = options && options.titleText ? options.titleText : t("errorPopupDefaultBody")
			bodyText.value = options && options.bodyText ? options.bodyText : t("errorPopupDefaultTitle")
			closeBtnText.value = options && options.closeBtnText ? options.closeBtnText : t("ok")
			closeCallback.value = options && options.closeCallback ? options.closeCallback : null
			acceptBtnText.value = options && options.acceptBtnText ? options.acceptBtnText : t("yes")
			acceptCallback.value = options && options.acceptCallback ? options.acceptCallback : null
			openModal()
		})

		$eventBus.$on(HIDE_ERROR, hideModal)
		$eventBus.$on(CART_CLEARED, hideModal)
	})

	onUnmounted(() => {
		$eventBus.$off(SHOW_ERROR)
		$eventBus.$off(HIDE_ERROR)
		$eventBus.$off(CART_CLEARED)
	})
</script>
