import { default as _91_46_46_46slug_938cdVAqJ2EYMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/[...slug].vue?macro=true";
import { default as indexTCfmCbw8YxMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/index.vue?macro=true";
import { default as loginVJR6fWVPk1Meta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/login.vue?macro=true";
import { default as maintenancexfifAnDmm9Meta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/maintenance.vue?macro=true";
import { default as checkoutq7oVFCHFUBMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/order/checkout.vue?macro=true";
import { default as confirmationvwIZ87zy7RMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/order/confirmation.vue?macro=true";
import { default as _91product_93NaeNYXJDbEMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/product/[product].vue?macro=true";
import { default as _91productType_93ZraHrDrrIoMeta } from "/home/runner/work/ticketco-tickets/ticketco-tickets/pages/products/[productType].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_938cdVAqJ2EYMeta?.name ?? "slug___en",
    path: _91_46_46_46slug_938cdVAqJ2EYMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_938cdVAqJ2EYMeta || {},
    alias: _91_46_46_46slug_938cdVAqJ2EYMeta?.alias || [],
    redirect: _91_46_46_46slug_938cdVAqJ2EYMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexTCfmCbw8YxMeta?.name ?? "index___en",
    path: indexTCfmCbw8YxMeta?.path ?? "/",
    meta: indexTCfmCbw8YxMeta || {},
    alias: indexTCfmCbw8YxMeta?.alias || [],
    redirect: indexTCfmCbw8YxMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginVJR6fWVPk1Meta?.name ?? "login___en",
    path: loginVJR6fWVPk1Meta?.path ?? "/login",
    meta: loginVJR6fWVPk1Meta || {},
    alias: loginVJR6fWVPk1Meta?.alias || [],
    redirect: loginVJR6fWVPk1Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/login.vue").then(m => m.default || m)
  },
  {
    name: maintenancexfifAnDmm9Meta?.name ?? "maintenance___en",
    path: maintenancexfifAnDmm9Meta?.path ?? "/maintenance",
    meta: maintenancexfifAnDmm9Meta || {},
    alias: maintenancexfifAnDmm9Meta?.alias || [],
    redirect: maintenancexfifAnDmm9Meta?.redirect || undefined,
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: checkoutq7oVFCHFUBMeta?.name ?? "order-checkout___en",
    path: checkoutq7oVFCHFUBMeta?.path ?? "/order/checkout",
    meta: checkoutq7oVFCHFUBMeta || {},
    alias: checkoutq7oVFCHFUBMeta?.alias || [],
    redirect: checkoutq7oVFCHFUBMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/order/checkout.vue").then(m => m.default || m)
  },
  {
    name: confirmationvwIZ87zy7RMeta?.name ?? "order-confirmation___en",
    path: confirmationvwIZ87zy7RMeta?.path ?? "/order/confirmation",
    meta: confirmationvwIZ87zy7RMeta || {},
    alias: confirmationvwIZ87zy7RMeta?.alias || [],
    redirect: confirmationvwIZ87zy7RMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/order/confirmation.vue").then(m => m.default || m)
  },
  {
    name: _91product_93NaeNYXJDbEMeta?.name ?? "product-product___en",
    path: _91product_93NaeNYXJDbEMeta?.path ?? "/product/:product()",
    meta: _91product_93NaeNYXJDbEMeta || {},
    alias: _91product_93NaeNYXJDbEMeta?.alias || [],
    redirect: _91product_93NaeNYXJDbEMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/product/[product].vue").then(m => m.default || m)
  },
  {
    name: _91productType_93ZraHrDrrIoMeta?.name ?? "products-productType___en",
    path: _91productType_93ZraHrDrrIoMeta?.path ?? "/products/:productType()",
    meta: _91productType_93ZraHrDrrIoMeta || {},
    alias: _91productType_93ZraHrDrrIoMeta?.alias || [],
    redirect: _91productType_93ZraHrDrrIoMeta?.redirect || undefined,
    component: () => import("/home/runner/work/ticketco-tickets/ticketco-tickets/pages/products/[productType].vue").then(m => m.default || m)
  }
]