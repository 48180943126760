export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()

	if (runtimeConfig.public.QUEUE_IT_ENABLED) {
		useHead({
			script: [
				{
					type: "text/javascript",
					src: "//static.queue-it.net/script/queueclient.min.js",
				},
				{
					...runtimeConfig.public.QUEUE_IT,
					type: "text/javascript",
					src: "//static.queue-it.net/script/queueconfigloader.min.js",
				},
			],
		})
	}
})
