<template>
	<ModalGenericModal
		:title="$t('cartTimeoutWarningTitle')"
		container-class="max-w-sm cartWarningPopupComponent"
		ref="modal"
		:hide-close-btn="true"
		:click-outside-enabled="false">
		<div class="pt-6 pb-3 px-6 relative z-30 text-popup-text bg-popup-bg border-x-2 border-popup-border">
			<p>{{ $t("cartTimeoutWarningBody", { minutes: warningTimeInMinutes() }) }}</p>
		</div>

		<div class="flex justify-center gap-8 py-4 bg-popup-bg border-b-2 border-x-2 rounded-b-lg border-popup-border">
			<ButtonModalClose
				:label="$t('close')"
				@click="closeModal" />
			<ButtonModalAccept
				:label="$t('xMoreMinutes', { minutes: warningTimeInMinutes() })"
				@click="extendCartExpiry" />
		</div>
	</ModalGenericModal>
</template>

<script setup>
	import { useNuxtApp, useRuntimeConfig } from "#app"
	import { onMounted, onUnmounted } from "vue"
	import { HIDE_CART_TIMEOUT_WARNING, SHOW_CART_TIMEOUT_WARNING } from "assets/events/cartTimeout"
	import { useRefsStore } from "~/stores/RefsStore"
	import { useCartStore } from "~/stores/CartStore"

	const runtimeConfig = useRuntimeConfig()
	const { $eventBus, $cartService } = useNuxtApp()
	const refsStore = useRefsStore()
	const cartStore = useCartStore()
	const modal = ref(null)

	const warningTimeInMinutes = () => {
		const warningTimeInMs = runtimeConfig.public.CART_TIMEOUT_WARNING_MS
		return warningTimeInMs / 1000 / 60
	}

	onMounted(() => {
		refsStore.setCartTimeoutWarningPopupRef(modal.value)

		$eventBus.$on(SHOW_CART_TIMEOUT_WARNING, () => {
			openModal()
		})

		$eventBus.$on(HIDE_CART_TIMEOUT_WARNING, () => {
			closeModal()
		})
	})

	onUnmounted(() => {
		$eventBus.$off(SHOW_CART_TIMEOUT_WARNING)
		$eventBus.$off(HIDE_CART_TIMEOUT_WARNING)
	})

	const openModal = () => refsStore.cartTimeoutWarningPopupRef.openModal()

	const closeModal = () => {
		refsStore.cartTimeoutWarningPopupRef.closeModal()
		cartStore.setHasShownExpiresWarning(true)
	}

	const extendCartExpiry = async () => {
		await $cartService.$extendCartTimeout()
		closeModal()
	}
</script>
