export default class Product {
	type: string
	id: string
	productTypeId: string
	eventId: string
	sortOrder: number
	sku: string
	title: string
	description: string
	shortDescription: string
	images: any
	availableQuantity: number
	isQuantityInCarts: boolean
	fees: any
	isInsurable: boolean
	isInstallmentEligible: boolean
	price: number
	termsAndConditions: string

	constructor(
		type: string,
		id: string,
		productTypeId: string,
		eventId: string,
		sortOrder: number,
		sku: string,
		title: string,
		description: string,
		shortDescription: string,
		images: any,
		availableQuantity: number,
		isQuantityInCarts: boolean,
		fees: any,
		isInsurable: boolean,
		isInstallmentEligible: boolean,
		price: number,
		termsAndConditions: string
	) {
		this.type = type
		this.id = id
		this.productTypeId = productTypeId
		this.eventId = eventId
		this.sortOrder = sortOrder
		this.sku = sku
		this.title = title
		this.description = description
		this.shortDescription = shortDescription
		this.images = images
		this.availableQuantity = availableQuantity
		this.isQuantityInCarts = isQuantityInCarts
		this.fees = fees
		this.isInsurable = isInsurable
		this.isInstallmentEligible = isInstallmentEligible
		this.price = price
		this.termsAndConditions = termsAndConditions
	}

	static createFromPartial(productPartial: Partial<Product>) {
		const defaults: Partial<Product> = {
			type: "",
			id: "",
			productTypeId: "",
			eventId: "",
			sortOrder: 0,
			sku: "",
			title: "",
			description: "",
			shortDescription: "",
			images: [],
			availableQuantity: Infinity,
			isQuantityInCarts: false,
			fees: 0,
			isInsurable: true,
			isInstallmentEligible: true,
			price: 0,
			termsAndConditions: "",
		}

		const mergedData = { ...defaults, ...productPartial }
		return new Product(
			mergedData.type as string,
			mergedData.id as string,
			mergedData.productTypeId as string,
			mergedData.eventId as string,
			mergedData.sortOrder as number,
			mergedData.sku as string,
			mergedData.title as string,
			mergedData.description as string,
			mergedData.shortDescription as string,
			mergedData.images as string,
			//A null value returned from the BE means that there is no limit for this product
			(mergedData.availableQuantity ?? Infinity) as number,
			mergedData.isQuantityInCarts as boolean,
			mergedData.fees as string,
			mergedData.isInsurable as boolean,
			mergedData.isInstallmentEligible as boolean,
			mergedData.price as number,
			mergedData.termsAndConditions as string
		)
	}
}
