<template>
	<ModalBase
		ref="baseModal"
		:click-outside-enabled="false">
		<div class="rounded-lg mx-auto bg-loader-bg border-2 border-loader-border loadingPopupComponent">
			<!-- Nuxt Img component not working once deployed - IPX issue -->
			<img
				:src="runtimeConfig.public.DEFAULT_LOADER"
				class="m-5 h-32 w-32"
				alt="Loading..." />
		</div>
	</ModalBase>
</template>

<script setup>
	import { useNuxtApp } from "#app"
	import { HIDE_API_LOADING, SHOW_API_LOADING } from "assets/events/api"
	import { useRefsStore } from "~/stores/RefsStore"

	const runtimeConfig = useRuntimeConfig()
	const { $eventBus } = useNuxtApp()
	const refsStore = useRefsStore()
	const baseModal = ref(null)

	onMounted(() => {
		refsStore.setLoadingModalRef(baseModal.value)

		$eventBus.$on(SHOW_API_LOADING, () => {
			openModal()
		})

		$eventBus.$on(HIDE_API_LOADING, () => {
			closeModal()
		})
	})

	onUnmounted(() => {
		$eventBus.$off(HIDE_API_LOADING)
		$eventBus.$off(SHOW_API_LOADING)
	})

	const openModal = () => refsStore.loadingModalRef.openModal()

	const closeModal = () => refsStore.loadingModalRef.closeModal()
</script>
