export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"property":"og:title","content":"Bass Coast Electronic Music and Art Festival"},{"property":"og:description","content":"Bass Coast Festival is an independent international exhibition of electronic music and art."},{"property":"og:type","content":"website"},{"property":"og:image","content":"https://tickets.basscoast.ca/images/active/meta-og-image.webp"},{"property":"og:url","content":"https://tickets.basscoast.ca"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/images/active/favicon_32x32.webp"},{"rel":"preload","type":"image/gif","href":"/images/active/loader.gif","as":"image"}],"style":[],"script":[{"src":"https://cdn-na.seatsio.net/chart.js","defer":true}],"noscript":[],"title":"Bass Coast","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null