<template>
	<div
		class="w-full fixed bottom-20 md:bottom-32 cookiesNoticePopupComponent"
		v-if="shouldShowCookiePopup">
		<div class="container h-30 bg-white p-4 z-30 mx-auto rounded-lg flex max-sm:flex-col gap-4 justify-between">
			<p>{{ $t("cookiesNotice") }}</p>

			<div class="flex gap-4 justify-evenly place-items-center">
				<ButtonModalClose
					:label="$t('decline')"
					class="w-32"
					@click="decline" />
				<ButtonModalAccept
					:label="$t('accept')"
					class="w-32"
					@click="accept" />
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useNuxtApp } from "nuxt/app"

	const { $cookiesConsent } = useNuxtApp()
	const shouldShowCookiePopup = ref(false)

	onMounted(() => {
		shouldShowCookiePopup.value = !$cookiesConsent.$hasUserDecided()
		$cookiesConsent.$init()
	})

	const accept = () => {
		$cookiesConsent.$acceptCookies()
		shouldShowCookiePopup.value = false
	}

	const decline = () => {
		$cookiesConsent.$declineCookies()
		shouldShowCookiePopup.value = false
	}
</script>
