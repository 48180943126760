export default {
	AD: "AD\\d{3}",
	AM: "(37)?\\d{4}",
	AR: "([A-HJ-NP-Z])?\\d{4}([A-Z]{3})?",
	AS: "96799",
	AT: "\\d{4}",
	AU: "\\d{4}",
	AX: "22\\d{3}",
	AZ: "\\d{4}",
	BA: "\\d{5}",
	BB: "(BB\\d{5})?",
	BD: "\\d{4}",
	BE: "\\d{4}",
	BG: "\\d{4}",
	BH: "((1[0-2]|[2-9])\\d{2})?",
	BM: "[A-Z]{2}[ ]?[A-Z0-9]{2}",
	BN: "[A-Z]{2}[ ]?\\d{4}",
	BR: "\\d{5}[-]?\\d{3}",
	BY: "\\d{6}",
	CA: "[ABCEGHJKLMNPRSTVXY]\\d[ABCEGHJ-NPRSTV-Z][ ]?\\d[ABCEGHJ-NPRSTV-Z]\\d",
	CC: "6799",
	CH: "\\d{4}",
	CK: "\\d{4}",
	CL: "\\d{7}",
	CN: "\\d{6}",
	CR: "\\d{4,5}|\\d{3}-\\d{4}",
	CS: "\\d{5}",
	CV: "\\d{4}",
	CX: "6798",
	CY: "\\d{4}",
	CZ: "\\d{3}[ ]?\\d{2}",
	DE: "\\d{5}",
	DK: "\\d{4}",
	DO: "\\d{5}",
	DZ: "\\d{5}",
	EC: "([A-Z]\\d{4}[A-Z]|(?:[A-Z]{2})?\\d{6})?",
	EE: "\\d{5}",
	EG: "\\d{5}",
	ES: "\\d{5}",
	ET: "\\d{4}",
	FI: "\\d{5}",
	FK: "FIQQ 1ZZ",
	FM: "(9694[1-4])([ -]\\d{4})?",
	FO: "\\d{3}",
	FR: "\\d{2}[ ]?\\d{3}",
	GB: "GIR[ ]?0AA|((AB|AL|B|BA|BB|BD|BH|BL|BN|BR|BS|BT|CA|CB|CF|CH|CM|CO|CR|CT|CV|CW|DA|DD|DE|DG|DH|DL|DN|DT|DY|E|EC|EH|EN|EX|FK|FY|G|GL|GY|GU|HA|HD|HG|HP|HR|HS|HU|HX|IG|IM|IP|IV|JE|KA|KT|KW|KY|L|LA|LD|LE|LL|LN|LS|LU|M|ME|MK|ML|N|NE|NG|NN|NP|NR|NW|OL|OX|PA|PE|PH|PL|PO|PR|RG|RH|RM|S|SA|SE|SG|SK|SL|SM|SN|SO|SP|SR|SS|ST|SW|SY|TA|TD|TF|TN|TQ|TR|TS|TW|UB|W|WA|WC|WD|WF|WN|WR|WS|WV|YO|ZE)(\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}))|BFPO[ ]?\\d{1,4}",
	GE: "\\d{4}",
	GF: "9[78]3\\d{2}",
	GG: "GY\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}",
	GL: "39\\d{2}",
	GN: "\\d{3}",
	GP: "9[78][01]\\d{2}",
	GR: "\\d{3}[ ]?\\d{2}",
	GS: "SIQQ 1ZZ",
	GT: "\\d{5}",
	GU: "969[123]\\d([ -]\\d{4})?",
	GW: "\\d{4}",
	HM: "\\d{4}",
	HN: "(?:\\d{5})?",
	HR: "\\d{5}",
	HT: "\\d{4}",
	HU: "\\d{4}",
	ID: "\\d{5}",
	IL: "\\d{5}",
	IM: "IM\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}",
	IN: "\\d{6}",
	IO: "BBND 1ZZ",
	IQ: "\\d{5}",
	IS: "\\d{3}",
	IT: "\\d{5}",
	JE: "JE\\d[\\dA-Z]?[ ]?\\d[ABD-HJLN-UW-Z]{2}",
	JO: "\\d{5}",
	JP: "\\d{3}-\\d{4}",
	KE: "\\d{5}",
	KG: "\\d{6}",
	KH: "\\d{5}",
	KR: "\\d{3}[-]\\d{3}",
	KW: "\\d{5}",
	KZ: "\\d{6}",
	LA: "\\d{5}",
	LB: "(\\d{4}([ ]?\\d{4})?)?",
	LI: "(948[5-9])|(949[0-7])",
	LK: "\\d{5}",
	LR: "\\d{4}",
	LS: "\\d{3}",
	LT: "\\d{5}",
	LU: "\\d{4}",
	LV: "\\d{4}",
	MA: "\\d{5}",
	MC: "980\\d{2}",
	MD: "\\d{4}",
	ME: "8\\d{4}",
	MK: "\\d{4}",
	MT: "[A-Z]{3}[ ]?\\d{2,4}",
	MU: "(\\d{3}[A-Z]{2}\\d{3})?",
	MV: "\\d{5}",
	MX: "\\d{5}",
	MY: "\\d{5}",
	NF: "2899",
	NG: "(\\d{6})?",
	NI: "((\\d{4}-)?\\d{3}-\\d{3}(-\\d{1})?)?",
	NL: "\\d{4}[ ]?[A-Z]{2}",
	NO: "\\d{4}",
	NP: "\\d{5}",
	NZ: "\\d{4}",
	OM: "(PC )?\\d{3}",
	PH: "\\d{4}",
	PK: "\\d{5}",
	PL: "\\d{2}-\\d{3}",
	PR: "00[679]\\d{2}([ -]\\d{4})?",
	PT: "\\d{4}([-]\\d{3})?",
	PY: "\\d{4}",
	RO: "\\d{6}",
	RS: "\\d{6}",
	RU: "\\d{6}",
	SA: "\\d{5}",
	SE: "\\d{3}[ ]?\\d{2}",
	SG: "\\d{6}",
	SI: "\\d{4}",
	SK: "\\d{3}[ ]?\\d{2}",
	SM: "4789\\d",
	SN: "\\d{5}",
	TH: "\\d{5}",
	TJ: "\\d{6}",
	TM: "\\d{6}",
	TN: "\\d{4}",
	TR: "\\d{5}",
	TW: "\\d{3}(\\d{2})?",
	UA: "\\d{5}",
	US: "\\d{5}([ -]\\d{4})?",
	UY: "\\d{5}",
	UZ: "\\d{6}",
	VA: "00120",
	VE: "\\d{4}",
	YU: "\\d{5}",
	ZA: "\\d{4}",
	ZM: "\\d{5}",
}
