import { HIDE_ERROR, SHOW_ERROR } from "assets/events/errors"

export default defineNuxtRouteMiddleware((to, from) => {
	if (process.client) {
		const { $messages, $eventBus } = useNuxtApp()

		//Use navigator API if browser supports it, otherwise assume the client is online
		const isOnline = navigator ? navigator.onLine : true

		if (!isOnline) {
			$eventBus.$emit(SHOW_ERROR, {
				titleText: $messages.$t("noInternet"),
				bodyText: $messages.$t("noInternetBody"),
				closeBtnText: $messages.$t("refresh"),
				closeCallback: reloadNuxtApp,
				acceptBtnText: $messages.$t("close"),
				acceptCallback: () => {
					$eventBus.$emit(HIDE_ERROR)
				},
			})
			return abortNavigation()
		}

		//If no return value, the router will continue
	}
})
