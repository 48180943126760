import { useNuxtApp } from "#app"

export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()
	const { $apiHelper } = useNuxtApp()

	const buildApiUrl = (appendPath) => {
		return (
			runtimeConfig.public.SERVICES.PRODUCTS_BASE_URL + encodeURIComponent(runtimeConfig.public.EVENT_ID) + appendPath
		)
	}

	nuxtApp.provide("productsApiService", {
		$getProductById: async (productId, exchangeRate = null) => {
			let url = buildApiUrl("/products/" + productId)

			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "GET"

			if (exchangeRate) {
				url += `?exchange-rate=${exchangeRate}`
			}

			const { data, error, status, pending } = useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while fetching the products",
				requestOptions
			)

			return { data, pending, status }
		},

		$getProductsByType: async (productTypeId, exchangeRate = null) => {
			let url = buildApiUrl("/producttypes/" + encodeURIComponent(productTypeId) + "/products")

			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "GET"

			if (exchangeRate) {
				url += `?exchange-rate=${exchangeRate}`
			}

			const { data, error, status, pending } = useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while fetching the products",
				requestOptions
			)

			return { data, pending, status }
		},

		$getAppSettings: async () => {
			const url = buildApiUrl("/settings")

			const requestOptions = $apiHelper.$initNoCachingHeader()

			const { data, error, status, pending } = useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while fetching the app settings",
				requestOptions
			)

			return { data, pending, status }
		},
	})
})
