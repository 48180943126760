<template>
	<ModalGenericModal
		:title="$t(`orderPlacedPopupTitle`)"
		container-class="max-w-sm checkoutConfirmationPopup"
		ref="modal"
		:click-outside-enabled="false"
		:hide-close-btn="true">
		<div class="break-words pt-6 pb-3 px-6 relative z-30 text-popup-text bg-popup-bg border-x-2 border-popup-border">
			<p>{{ $t("orderPlacedPopupBody") }}</p>
		</div>

		<div class="flex justify-center gap-8 py-4 bg-popup-bg border-b-2 border-x-2 rounded-b-lg border-popup-border">
			<ButtonModalAccept
				:label="$t(`ok`)"
				@click="acceptModal" />
		</div>
	</ModalGenericModal>
</template>

<script setup>
	import { useNuxtApp } from "#app"
	import { useRefsStore } from "~/stores/RefsStore"
	import { HIDE_ORDER_PLACED_POPUP, SHOW_ORDER_PLACED_POPUP } from "assets/events/checkout.js"

	//The confirmation page was failing to load for about 1% of users. So this popup was created to inform the user that their order has been placed.
	//This was the interim solution until a solution can be found for the confirmation page not loading.

	const { $eventBus } = useNuxtApp()
	const refsStore = useRefsStore()
	const modal = ref(null)

	const SHOW_ORDER_CONFIRMATION_POPUP = "SHOW_ORDER_CONFIRMATION_POPUP"

	const openModal = () => {
		refsStore.confirmationPopupRef.openModal()
		document.cookie = `${SHOW_ORDER_CONFIRMATION_POPUP}=true;max-age=120;path=/`
	}
	const acceptModal = () => {
		refsStore.confirmationPopupRef.closeModal()
		document.cookie = `${SHOW_ORDER_CONFIRMATION_POPUP}=;max-age=0;path=/`
		navigateTo({ path: "/order/confirmation" })
	}

	const shouldShowPopup = () => {
		const cookieString = document.cookie
		return cookieString.indexOf(SHOW_ORDER_CONFIRMATION_POPUP) >= 0
	}

	onMounted(() => {
		refsStore.setConfirmationPopupRef(modal.value)

		if (shouldShowPopup()) {
			//open the popup in case the page is refreshed
			openModal()
		}

		$eventBus.$on(SHOW_ORDER_PLACED_POPUP, openModal)

		$eventBus.$on(HIDE_ORDER_PLACED_POPUP, acceptModal)
	})

	onUnmounted(() => {
		$eventBus.$off(SHOW_ORDER_PLACED_POPUP)
		$eventBus.$on(HIDE_ORDER_PLACED_POPUP)
	})
</script>
