<template>
	<div>
		<transition name="modal-fade">
			<!-- Modal Overlay -->
			<div
				class="fixed inset-0 z-30 flex items-center justify-center bg-black bg-opacity-70"
				v-if="isOpen"
				@click="clickOutsideEnabled ? closeModal() : ''">
				<!-- Modal Content -->
				<transition name="modal-slide">
					<div
						:class="containerClass"
						@click="(e) => e.stopPropagation()">
						<slot></slot>
					</div>
				</transition>
			</div>
		</transition>
	</div>
</template>

<script setup>
	defineProps({
		containerClass: String,
		clickOutsideEnabled: {
			type: Boolean,
			default: true,
		},
	})

	const isOpen = ref(false)

	const openModal = () => {
		isOpen.value = true
	}

	const closeModal = () => {
		isOpen.value = false
	}

	const isModalOpen = () => {
		return isOpen.value
	}

	defineExpose({
		openModal,
		closeModal,
		isModalOpen,
	})
</script>

<style lang="scss" scoped></style>
