<template>
	<slot></slot>
</template>

<script setup>
	const { $utilities } = useNuxtApp()

	onErrorCaptured((err, instance, info) => {
		return $utilities.$handleImportModuleError(err.message)
	})

	const router = useRouter()
	router.onError((error, to) => {
		$utilities.$handleImportModuleError(error.message)
	})
</script>
