export default defineNuxtPlugin((nuxtApp) => {
	/*
			Config Values can be configured as a boolean or an object format below.
			ENABLED will be the primary value which will be overridden by the DISABLED dates.
			DISABLED dates should include the timezone of the event

			Example object format:
			ENABLE_REFUNDABLE_BOOKING: {
				ENABLED: true,
				DISABLED_STARTING_AT: "2024-08-30 00:00:00 GMT-0600",
				DISABLED_ENDING_AT: null,
			},
	 */
	const isConfigEnabled = (configVal) => {
		if (typeof configVal === "boolean") {
			return configVal
		} else if (typeof configVal !== "object") {
			throw createError({ statusCode: 500, statusMessage: `Unexpected config format detected` })
		}

		const isEnabled = configVal.ENABLED
		if (!isEnabled) {
			return false
		}

		const now = new Date()
		const startDate = configVal.DISABLED_STARTING_AT ? new Date(configVal.DISABLED_STARTING_AT) : null
		const endDate = configVal.DISABLED_ENDING_AT ? new Date(configVal.DISABLED_ENDING_AT) : null

		//If config is enabled, compare the current date to the disabling date range
		if (startDate && endDate) {
			return !(now > startDate && now < endDate)
		}
		if (startDate) {
			return now < startDate
		}
		if (endDate) {
			return now > endDate
		}

		// Fallback (should not reach here if config is valid)
		return isEnabled
	}

	nuxtApp.provide("configs", {
		$isConfigEnabled: isConfigEnabled,
	})
})
