export const usePaysafeStore = defineStore("paysafeStore", () => {
	const token = ref("")

	const setToken = (value = "") => (token.value = value)

	return {
		token: token,
		setToken,
	}
})
