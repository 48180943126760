import { useCartStore } from "~/stores/CartStore"
import { useCurrencyStore } from "~/stores/CurrencyStore"
import { useProductStore } from "~/stores/ProductStore"
import { useOrderStore } from "~/stores/OrderStore"
import { useSettingsStore } from "~/stores/SettingsStore"

//This plugin is in place to allow for persistent stores. If the page is refreshed, the store will be repopulated by this plugin.
export default defineNuxtPlugin((nuxtApp) => {
	const cartStore = useCartStore()
	const productStore = useProductStore()
	const currencyStore = useCurrencyStore()
	const orderStore = useOrderStore()
	const settingsStore = useSettingsStore()

	nuxtApp.provide("persistentStores", {
		$init: () => {
			productStore.$subscribe((mutation, state) => {
				const clonedState = { ...state }
				clonedState.products = Object.fromEntries(state.products)
				localStorage.setItem("productStore", JSON.stringify(clonedState))
			})

			cartStore.$subscribe((mutation, state) => {
				// persist the whole cart state to the local storage whenever it changes
				const clonedState = { ...state }
				//Need to create an object from the items Map object in the store to properly stringify the object
				clonedState.items = Object.fromEntries(state.items)
				clonedState.expiresAt = state.expiresAt ? state.expiresAt.getTime() : ""
				localStorage.setItem("cartStore", JSON.stringify(clonedState))
			})

			orderStore.$subscribe((mutation, state) => {
				const clonedState = { ...state }
				//Need to create an object from the items Map object in the store to properly stringify the object
				clonedState.items = Object.fromEntries(state.items)
				localStorage.setItem("orderStore", JSON.stringify(clonedState))
			})

			currencyStore.$subscribe((mutation, state) => {
				const clonedState = { ...state }
				localStorage.setItem("currencyStore", JSON.stringify(clonedState))
			})

			settingsStore.$subscribe((mutation, state) => {
				const clonedState = { ...state }
				localStorage.setItem("settingsStore", JSON.stringify(clonedState))
			})
		},

		//Reload all vue stores with what was stored in the browsers storage
		$refresh: () => {
			const products = JSON.parse(localStorage.getItem("productStore"))
			if (products) {
				products.products = new Map(Object.entries(products.products))
				productStore.setProductStore(products)
			}

			const cart = JSON.parse(localStorage.getItem("cartStore"))
			if (cart) {
				cart.items = new Map(Object.entries(cart.items))
				cart.expiresAt = cart.expiresAt ? new Date(cart.expiresAt) : null
				if (cart.expiresAt) {
					cartStore.setEntireCart(cart)
				} else {
					cartStore.$reset()
				}
			}

			const order = JSON.parse(localStorage.getItem("orderStore"))
			if (order) {
				order.items = new Map(Object.entries(order.items))
				orderStore.setEntireOrder(order)
			}

			const currency = JSON.parse(localStorage.getItem("currencyStore"))
			if (currency) {
				currencyStore.setCurrencyStore(currency)
			}

			const settings = JSON.parse(localStorage.getItem("settingsStore"))
			if (settings) {
				settingsStore.setSettingsStore(settings)
			}
		},
	})
})
