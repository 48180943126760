export default defineNuxtPlugin((nuxtApp) => {
	const COOKIES_CONSENT = "COOKIES_CONSENT"
	const ACCEPTED = "ACCEPTED"
	const DECLINED = "DECLINED"

	const { $mouseflow, $sentry } = useNuxtApp()

	const getCookieValue = () => {
		const cookieString = document.cookie
		const cookies = cookieString.split(";")

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim()
			if (cookie.startsWith(`${COOKIES_CONSENT}=`)) {
				return cookie.substring(COOKIES_CONSENT.length + 1)
			}
		}

		return null
	}

	const setConsentCookie = (hasUserAccepted) => {
		const newExpirationDate = new Date()
		// Add 6 months to the current date
		newExpirationDate.setMonth(newExpirationDate.getMonth() + 6)

		document.cookie = `${COOKIES_CONSENT}=${
			hasUserAccepted ? ACCEPTED : DECLINED
		};expires=${newExpirationDate.toUTCString()};path=/`
	}

	const initiateTrackers = () => {
		$mouseflow.$init()
		$sentry.$init()
	}

	nuxtApp.provide("cookiesConsent", {
		$init: () => {
			const hasUserDecided = getCookieValue()

			if (hasUserDecided && hasUserDecided === ACCEPTED) {
				initiateTrackers()
			}
		},

		$hasUserDecided: () => {
			return !!getCookieValue()
		},

		$acceptCookies: () => {
			setConsentCookie(true)
			initiateTrackers()
		},

		$declineCookies: () => {
			setConsentCookie(false)
		},
	})
})
