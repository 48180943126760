import countriesAndStates from "~/assets/static/countries_states.js"
import getCountryISO2 from "~/assets/static/countryIso3ToIso2.js"

export default defineNuxtPlugin((nuxtApp) => {
	const getCountryByIsoCode = (iso3) => {
		return countriesAndStates.data.find((c) => c.iso3 === iso3)
	}

	const createCountryDropdownOptions = (country) => {
		return { label: country.name, value: country.iso3 }
	}

	const createStateDropdownOptions = (state) => {
		return { label: state.name, value: state.state_code }
	}

	nuxtApp.provide("countryAndStatesService", {
		$getCountryByIsoCode: getCountryByIsoCode,

		$getSupportedCountries: () => {
			return countriesAndStates.data.map(createCountryDropdownOptions)
		},

		$getStatesForCountryByIsoCode: (iso3) => {
			const country = getCountryByIsoCode(iso3)
			if (!country) {
				throw createError({ statusCode: 500, statusMessage: `Unable to find the country code: ${iso3}` })
			}
			return country.states.map(createStateDropdownOptions)
		},

		$convertIso3ToIso2: (iso3) => {
			return getCountryISO2(iso3)
		},
	})
})
