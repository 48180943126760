import patterns from "~/assets/static/postalCodePatterns.js"
import getCountryISO2 from "~/assets/static/countryIso3ToIso2.js"

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.provide("postalCodeService", {
		$getPostalCodePattern: (countryIso3) => {
			const iso2 = getCountryISO2(countryIso3)
			const pattern = patterns[iso2]
			if (!pattern) {
				return new RegExp(".*")
			} else {
				return new RegExp(pattern)
			}
		},
	})
})
