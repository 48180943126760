//Checkout Related Events
export const CHECKOUT_SUBMIT = "checkout:submit"
export const ENABLE_PLACE_ORDER = "checkout:done_submitting"

export const SHOW_ORDER_PLACED_POPUP = "checkout:order_placed_popup"

export const HIDE_ORDER_PLACED_POPUP = "checkout:order_confirmation_screen_reached"

//Paysafe Related Events
export const PAYSAFE_TOKENIZE = "paysafe:tokenize"
export const PAYSAFE_TOKENIZED = "paysafe:tokenized"

//Miscellaneous
export const OPEN_CURRENCY_SELECTOR = "OPEN_CURRENCY_SELECTOR"
