//This store is used to store component refs (Vue Template Refs) that lose their references when navigating away from a page.
//This was causing javascript errors when the user would click between pages erratically or try to order multiple times in a row.
export const useRefsStore = defineStore("refsStore", () => {
	const shippingInformationRef = ref(null)
	const setShippingInformationRef = (value = null) => (shippingInformationRef.value = value)

	const paymentInformationRef = ref(null)
	const setPaymentInformationRef = (value = null) => (paymentInformationRef.value = value)

	const billingInformationRef = ref(null)
	const setBillingInformationRef = (value = null) => (billingInformationRef.value = value)

	const loadingModalRef = ref(null)
	const setLoadingModalRef = (value = null) => (loadingModalRef.value = value)

	const errorPopupRef = ref(null)
	const setErrorPopupRef = (value = null) => (errorPopupRef.value = value)

	const cartTimeoutWarningPopupRef = ref(null)
	const setCartTimeoutWarningPopupRef = (value = null) => (cartTimeoutWarningPopupRef.value = value)

	const cartTimeoutExpiredPopupRef = ref(null)
	const setCartTimeoutExpiredPopupRef = (value = null) => (cartTimeoutExpiredPopupRef.value = value)

	const confirmationPopupRef = ref(null)
	const setConfirmationPopupRef = (value = null) => (confirmationPopupRef.value = value)

	const seatSelectorPopupRef = ref(null)
	const setSeatSelectorPopupRef = (value = null) => (seatSelectorPopupRef.value = value)

	const seatsObjectRef = ref(null)
	const setSeatsObjectRef = (value = null) => (seatsObjectRef.value = value)

	const seatPreviewPopupRef = ref(null)
	const setSeatPreviewPopupRef = (value = null) => (seatPreviewPopupRef.value = value)

	const seatsPreviewObjectRef = ref(null)
	const setSeatsPreviewObjectRef = (value = null) => (seatsPreviewObjectRef.value = value)

	return {
		shippingInformationRef,
		setShippingInformationRef,
		paymentInformationRef,
		setPaymentInformationRef,
		billingInformationRef,
		setBillingInformationRef,
		loadingModalRef,
		setLoadingModalRef,
		errorPopupRef,
		setErrorPopupRef,
		cartTimeoutWarningPopupRef,
		setCartTimeoutWarningPopupRef,
		cartTimeoutExpiredPopupRef,
		setCartTimeoutExpiredPopupRef,
		confirmationPopupRef,
		setConfirmationPopupRef,
		seatSelectorPopupRef,
		setSeatSelectorPopupRef,
		seatsObjectRef,
		setSeatsObjectRef,
		seatPreviewPopupRef,
		setSeatPreviewPopupRef,
		seatsPreviewObjectRef,
		setSeatsPreviewObjectRef,
	}
})
