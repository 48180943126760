export default defineNuxtPlugin((nuxtApp) => {
	let $t = null

	nuxtApp.provide("messages", {
		$init: (t) => {
			$t = t
		},

		$t: (key, options) => $t(key, options),
	})
})
