export default class CartItem {
	id: string
	hasLimitedQuantity: boolean
	quantity: number
	productType: string
	isInstallmentEligible: boolean
	discountedTotal: number
	isPromocodeEligible: boolean
	total: number
	isRemovable: boolean
	fees: {}
	isInsurable: boolean
	subtotal: number
	expiresAt: number
	images: string[]
	sk: string
	pk: string
	price: number
	baseFees: {}
	sku: string
	title: string
	reservationSection: string
	reservationSelectedSeats: string[]

	constructor(
		id: string,
		hasLimitedQuantity: boolean,
		quantity: number,
		productType: string,
		isInstallmentEligible: boolean,
		discountedTotal: number,
		isPromocodeEligible: boolean,
		total: number,
		isRemovable: boolean,
		fees: object,
		isInsurable: boolean,
		subtotal: number,
		expiresAt: number,
		images: string[],
		sk: string,
		pk: string,
		price: number,
		baseFees: object,
		sku: string,
		title: string,
		reservationSection: string,
		reservationSelectedSeats: string[]
	) {
		this.id = id
		this.hasLimitedQuantity = hasLimitedQuantity
		this.quantity = quantity
		this.productType = productType
		this.isInstallmentEligible = isInstallmentEligible
		this.discountedTotal = discountedTotal
		this.isPromocodeEligible = isPromocodeEligible
		this.total = total
		this.isRemovable = isRemovable
		this.fees = fees
		this.isInsurable = isInsurable
		this.subtotal = subtotal
		this.expiresAt = expiresAt
		this.images = images
		this.sk = sk
		this.pk = pk
		this.price = price
		this.baseFees = baseFees
		this.sku = sku
		this.title = title
		this.reservationSection = reservationSection
		this.reservationSelectedSeats = reservationSelectedSeats
	}

	static createFromPartial(cartItemPartial: Partial<CartItem>) {
		const defaults: Partial<CartItem> = {
			id: "",
			hasLimitedQuantity: false,
			quantity: 0,
			productType: "",
			isInstallmentEligible: false,
			discountedTotal: 0,
			isPromocodeEligible: false,
			total: 0,
			isRemovable: false,
			fees: {},
			isInsurable: false,
			subtotal: 0,
			expiresAt: 0,
			images: [],
			sk: "",
			pk: "",
			price: 0,
			baseFees: {},
			sku: "",
			title: "",
			reservationSection: "",
			reservationSelectedSeats: [],
		}

		const mergedData = { ...defaults, ...cartItemPartial }
		return new CartItem(
			mergedData.id as string,
			mergedData.hasLimitedQuantity as boolean,
			mergedData.quantity as number,
			mergedData.productType as string,
			mergedData.isInstallmentEligible as boolean,
			mergedData.discountedTotal as number,
			mergedData.isPromocodeEligible as boolean,
			mergedData.total as number,
			mergedData.isRemovable as boolean,
			mergedData.fees as object,
			mergedData.isInsurable as boolean,
			mergedData.subtotal as number,
			mergedData.expiresAt as number,
			mergedData.images as string[],
			mergedData.sk as string,
			mergedData.pk as string,
			mergedData.price as number,
			mergedData.baseFees as object,
			mergedData.sku as string,
			mergedData.title as string,
			mergedData.reservationSection as string,
			mergedData.reservationSelectedSeats as string[]
		)
	}

	static sortCartItems(cartItems: IterableIterator<CartItem>) {
		const allItems = [...cartItems]
		return allItems.sort((a, b) => {
			if (a.isRemovable === b.isRemovable) {
				return 0
			}
			return b.isRemovable ? 1 : -1
		})
	}
}
