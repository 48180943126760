<template>
	<div class="background bg-black h-screen align-middle m-0 w-full">
		<header>
			<nav class="h-20 flex items-center mb-8">
				<HeaderLogo class="mx-auto !block" />
			</nav>
		</header>

		<NuxtLayout name="one-column-layout">
			<template #title>{{ $t("errorPageTitle") }}</template>

			<template #subtitle>
				<p>{{ $t("errorPageBody") }}</p>
			</template>

			<template #contentSlot1>
				<p class="text-primary">{{ error && error.message }}</p>
			</template>

			<template #contentSlot2>
				<ButtonPrimary
					class="w-60 self-center"
					@click="goToBeginning">
					<p>{{ $t("backToBeginning") }}</p>
				</ButtonPrimary>
			</template>
		</NuxtLayout>
	</div>
</template>

<script setup>
	const { error } = defineProps({
		error: Object,
	})

	//Set the Page Metadata
	useHead(() => {
		return {
			title: "Error",
			meta: [{ name: "description", content: `There was an error` }],
		}
	})

	const goToBeginning = () => clearError({ redirect: "/" })
</script>

<style lang="scss" scoped>
	.background {
		background-color: black;
		background-image: url($background_image);
		background-size: contain;
		background-repeat: no-repeat;

		@media screen and (min-width: 1400px) {
			background-position-y: -60px;
		}
	}
</style>
