<template>
	<button
		:id="id"
		class="font-bold text-sm rounded-full px-6 uppercase h-10 hoverEffects disabled:bg-gray-400 disabled:text-gray-500"
		:disabled="disabled"
		@click="clicked">
		<slot />
	</button>
</template>

<script setup>
	const { id } = defineProps({
		id: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	})

	const emits = defineEmits(["clicked"])
	const clicked = () => {
		emits("clicked", { id: id })
	}
</script>

<style lang="scss"></style>
