import { useNuxtApp } from "#app"

export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()
	const { $apiHelper } = useNuxtApp()

	const buildApiUrl = (appendPath) => {
		return (
			runtimeConfig.public.SERVICES.CART_BASE_URL +
			encodeURIComponent(runtimeConfig.public.EVENT_ID) +
			"/cart" +
			appendPath
		)
	}

	nuxtApp.provide("cartApiService", {
		$getCart: async (cartId) => {
			const url = buildApiUrl("")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "GET"
			requestOptions.headers = {
				cartid: cartId,
			}

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while fetching the cart",
				requestOptions
			)

			return { data, pending, status }
		},

		//Called whenever an item is added/updated/removed from the cart
		$setCartItemQty: async (cartId, productId, currencyCode, quantity, price) => {
			const url = buildApiUrl("/items/" + productId)
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "POST"
			requestOptions.body = {
				quantity: quantity,
				currency: currencyCode,
				price: price,
			}

			//Update the existing cart if the cartStore has a cartId. This method is also used to create a new cart.
			if (cartId) {
				requestOptions.headers = {
					cartid: cartId,
				}
			}

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while updating the cart items",
				requestOptions
			)

			return { data, pending, status }
		},

		//Called whenever an item removed from the cart
		$deleteCartItem: async (cartId, productId) => {
			const url = buildApiUrl("/items/" + productId)
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "DELETE"
			requestOptions.headers = {
				cartid: cartId,
			}

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while deleting the cart item",
				requestOptions
			)

			return { data, pending, status }
		},

		$updateInstallments: async (cartId, numInstallments) => {
			const url = buildApiUrl("/installments")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "PATCH"
			requestOptions.headers = {
				cartid: cartId,
			}
			requestOptions.body = { installmentCount: numInstallments }

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while getting installments",
				requestOptions
			)

			return { data, pending, status }
		},

		$placeOrder: async (cartId, cartBody) => {
			const url = buildApiUrl("/checkout")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "POST"
			requestOptions.headers = {
				cartid: cartId,
			}
			requestOptions.body = cartBody

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while placing the order",
				requestOptions
			)

			return { data, pending, status }
		},

		$extendCartTimeout: async (cartId) => {
			const url = buildApiUrl("/scheduleExpiredCart")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "POST"
			requestOptions.headers = {
				cartid: cartId,
			}
			requestOptions.body = {
				requestedExtension: true,
			}

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while extending the cart timeout",
				requestOptions
			)

			return { data, pending, status }
		},

		$updateRefundableBooking: async (cartId, isRefundableBookingSelected) => {
			const url = buildApiUrl("/refundable-booking")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "PATCH"
			requestOptions.headers = {
				cartid: cartId,
			}
			requestOptions.body = {
				isRefundableBookingSelected: isRefundableBookingSelected,
			}

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while selecting refundable booking",
				requestOptions
			)

			return { data, pending, status }
		},

		$setPromoCode: async (cartId, promoCode, firstname, lastname, email) => {
			const url = buildApiUrl("/promocode")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "POST"
			requestOptions.headers = {
				cartid: cartId,
			}
			requestOptions.body = {
				promocode: promoCode,
				user: {
					firstName: firstname,
					lastName: lastname,
					email: email,
				},
			}

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while applying the coupon code",
				requestOptions
			)

			return { data, pending, status }
		},

		$updateCartCurrency: async (cartId, currencyCode) => {
			const url = buildApiUrl("/currency")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "PATCH"
			requestOptions.headers = {
				cartid: cartId,
			}
			requestOptions.body = {
				currency: currencyCode,
			}

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while switching currency",
				requestOptions
			)

			return { data, pending, status }
		},

		$updateSeatSelection: async (cartId, holdToken, productSeatSelection) => {
			const url = buildApiUrl("/seats")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "PATCH"
			requestOptions.headers = {
				cartid: cartId,
				reservationHoldToken: holdToken,
			}
			requestOptions.body = productSeatSelection

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while updating the seat selection",
				requestOptions
			)

			return { data, pending, status }
		},

		$clearCart: async (cartId) => {
			const url = buildApiUrl("")
			const requestOptions = $apiHelper.$initNoCachingHeader()
			requestOptions.method = "DELETE"
			requestOptions.headers = {
				cartid: cartId,
			}

			const { data, pending, error, status } = await useFetch(url, requestOptions)

			$apiHelper.$handleAPIErrors(
				pending,
				status,
				error,
				"Something went wrong while clearing the cart",
				requestOptions
			)

			return { data, pending, status }
		},
	})
})
