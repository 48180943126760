// https://dev.to/ceceliacreates/using-fontawesome-icons-with-nuxt-3-50cd

import { config, library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"

//imports the entire library, could destructure the specific components for a smaller build file
//https://fontawesome.com/docs/apis/javascript/icon-library#subsetting
import { fas } from "@fortawesome/free-solid-svg-icons"

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

// You can add your icons directly in this plugin.
library.add(fas)

export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.component("font-awesome-icon", FontAwesomeIcon, {})
})
