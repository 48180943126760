import Product from "~/models/Product"

export const useProductStore = defineStore("productStore", () => {
	const products = ref(new Map<string, Product>())

	const getProductsOfType = (productTypeId: string) => {
		const productList: Product[] = []
		products.value.forEach((product, key) => {
			if (product.productTypeId === productTypeId) {
				productList.push(product)
			}
		})

		productList.sort((a, b) => {
			return a.sortOrder - b.sortOrder
		})

		return productList
	}

	const getProductById = (id: string) => {
		return products.value.get(id)
	}

	const addProduct = (product: Product) => {
		products.value.set(product.id, product)
	}

	const removeProduct = (product: Product) => {
		products.value.delete(product.id)
	}

	const setProductStore = (productStore: any) => {
		products.value = productStore.products
	}

	const removeProductsByTypeId = (productTypeId: string) => {
		const existingProductsByType = [...products.value.values()].filter(
			(value) => (value as Product).productTypeId === productTypeId
		)
		existingProductsByType.forEach((product) => removeProduct(product as Product))
	}

	const $reset = () => {
		products.value.clear()
	}

	return {
		products,
		getProductsOfType,
		getProductById,
		addProduct,
		setProductStore,
		removeProduct,
		removeProductsByTypeId,
		$reset,
	}
})
