<template>
	<ModalBase
		ref="baseModal"
		:container-class="containerClass"
		:click-outside-enabled="clickOutsideEnabled">
		<div class="min-w-[20em] relative">
			<div
				class="h-[7vh] md:h-16 flex justify-between items-center shadow-md rounded-t-lg px-6 bg-popup-headerBg text-popup-headerText modalBorder relative z-40">
				<div>
					<p class="font-serif text-xl break-words">{{ title }}</p>
				</div>
				<div
					v-if="!hideCloseBtn"
					class="bg-popup-headerBg">
					<IconsFontAwesome
						icon="fa-solid fa-xmark"
						class="hover:cursor-pointer"
						@click="closeModal" />
				</div>
			</div>

			<slot></slot>
		</div>
	</ModalBase>
</template>

<script setup>
	defineProps({
		title: {
			type: String,
			required: true,
		},
		containerClass: String,
		clickOutsideEnabled: {
			type: Boolean,
			default: true,
		},
		hideCloseBtn: {
			type: Boolean,
			default: false,
		},
	})

	const baseModal = ref(null)

	const openModal = () => {
		baseModal.value.openModal()
	}

	const closeModal = () => {
		baseModal.value.closeModal()
	}

	defineExpose({
		openModal,
		closeModal,
	})
</script>

<style scoped>
	.modalBorder {
		@apply border-popup-border;
		@apply border-b border-t-2 border-x-2;
	}
</style>
