export default defineNuxtPlugin((nuxtApp) => {
	const runtimeConfig = useRuntimeConfig()

	//Mouseflow is added as a plugin so that the user can choose to disable cookies and tracking
	nuxtApp.provide("mouseflow", {
		$init: () => {
			const mouseflowCDNLink = runtimeConfig.public.MOUSEFLOW_CDN
			if (process.client && mouseflowCDNLink) {
				window._mfq = window._mfq || []
				;(function () {
					var mf = document.createElement("script")
					mf.type = "text/javascript"
					mf.defer = true
					mf.src = mouseflowCDNLink
					document.getElementsByTagName("head")[0].appendChild(mf)
				})()
			}
		},
	})
})
