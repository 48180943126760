import mitt from "mitt"

const emitter = mitt()
export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.provide("eventBus", {
		//listeners need to be added after a component is mounted, otherwise the eventBus object has not been instantiated
		$on: emitter.on,

		//Listeners need to be removed after the component is unmounted, otherwise you will see duplicate executions of the listeners
		$off: emitter.off,

		//emitters cannot be called during the setup method, otherwise the eventBus object has not been instantiated
		//Ie. add to an event listener
		$emit: emitter.emit,
	})
})
