import CartItem from "~/models/CartItem"

export const useOrderStore = defineStore("orderStore", () => {
	const orderId = ref("")
	const firstName = ref("")
	const lastName = ref("")
	const email = ref("")
	const address1 = ref("")
	const address2 = ref("")
	const country = ref("")
	const state = ref("")
	const city = ref("")
	const zipCode = ref("")

	const shippingFirstName = ref("")
	const shippingLastName = ref("")
	const shippingAddress1 = ref("")
	const shippingAddress2 = ref("")
	const shippingCity = ref("")
	const shippingState = ref("")
	const shippingCountry = ref("")
	const shippingZip = ref("")

	const items = ref(new Map<string, CartItem>())
	const subtotal = ref(0)
	const total = ref(0)
	const fees = ref({})
	const refundableBookingCost = ref(0)
	const discountedAmount = ref(0)
	const installments = ref({})

	const lastFourDigits = ref("")
	const createdAt = ref("")

	const setOrderId = (value = "") => (orderId.value = value)

	const setFirstName = (value = "") => (firstName.value = value)

	const setLastName = (value = "") => (lastName.value = value)

	const setEmail = (value = "") => (email.value = value)

	const setAddress1 = (value = "") => (address1.value = value)

	const setAddress2 = (value = "") => (address2.value = value)

	const setCountry = (value = "") => (country.value = value)

	const setState = (value = "") => (state.value = value)

	const setCity = (value = "") => (city.value = value)

	const setZipCode = (value = "") => (zipCode.value = value)

	const setShippingFirstName = (value = "") => (shippingFirstName.value = value)

	const setShippingLastName = (value = "") => (shippingLastName.value = value)

	const setShippingAddress1 = (value = "") => (shippingAddress1.value = value)

	const setShippingAddress2 = (value = "") => (shippingAddress2.value = value)

	const setShippingCity = (value = "") => (shippingCity.value = value)

	const setShippingState = (value = "") => (shippingState.value = value)

	const setShippingCountry = (value = "") => (shippingCountry.value = value)

	const setShippingZip = (value = "") => (shippingZip.value = value)

	const addItem = (item: CartItem) => {
		items.value.set(item.id, item)
	}

	const getItems = () => {
		return CartItem.sortCartItems(items.value.values())
	}

	const setSubTotal = (value: number) => (subtotal.value = value)

	const setTotal = (value: number) => (total.value = value)

	const setFees = (value: object) => (fees.value = value)

	const setRefundableBookingCost = (value: number) => (refundableBookingCost.value = value)

	const setDiscountedAmount = (value = 0) => (discountedAmount.value = value)

	const setInstallments = (value: object) => (installments.value = value)

	const setLastFourDigits = (value: string) => (lastFourDigits.value = value)

	const setCreatedAt = (utcDateString: string) => {
		const date = new Date(utcDateString)
		const year = date.getFullYear()
		const month = String(date.getMonth() + 1).padStart(2, "0")
		const day = String(date.getDate()).padStart(2, "0")
		// Format as YYYY-MM-DD
		createdAt.value = `${year}-${month}-${day}`
	}

	const setEntireOrder = (orderStore: any) => {
		orderId.value = orderStore.orderId
		firstName.value = orderStore.firstName
		lastName.value = orderStore.lastName
		email.value = orderStore.email
		address1.value = orderStore.address1
		address2.value = orderStore.address2
		country.value = orderStore.country
		state.value = orderStore.state
		city.value = orderStore.city
		zipCode.value = orderStore.zipCode
		shippingFirstName.value = orderStore.shippingFirstName
		shippingLastName.value = orderStore.shippingLastName
		shippingAddress1.value = orderStore.shippingAddress1
		shippingAddress2.value = orderStore.shippingAddress2
		shippingCity.value = orderStore.shippingCity
		shippingState.value = orderStore.shippingState
		shippingCountry.value = orderStore.shippingCountry
		shippingZip.value = orderStore.shippingZip
		items.value = orderStore.items
		subtotal.value = orderStore.subtotal
		total.value = orderStore.total
		fees.value = orderStore.fees
		refundableBookingCost.value = orderStore.refundableBookingCost
		discountedAmount.value = orderStore.discountedAmount
		installments.value = orderStore.installments
		lastFourDigits.value = orderStore.lastFourDigits
		createdAt.value = orderStore.createdAt
	}

	const $reset = () => {
		orderId.value = ""
		firstName.value = ""
		lastName.value = ""
		email.value = ""
		address1.value = ""
		address2.value = ""
		country.value = ""
		state.value = ""
		city.value = ""
		zipCode.value = ""
		shippingFirstName.value = ""
		shippingLastName.value = ""
		shippingAddress1.value = ""
		shippingAddress2.value = ""
		shippingCity.value = ""
		shippingState.value = ""
		shippingCountry.value = ""
		shippingZip.value = ""
		items.value = new Map<string, CartItem>()
		subtotal.value = 0
		total.value = 0
		fees.value = {}
		refundableBookingCost.value = 0
		discountedAmount.value = 0
		installments.value = {}
		lastFourDigits.value = ""
		createdAt.value = ""
	}

	return {
		orderId,
		setOrderId,
		firstName,
		setFirstName,
		lastName,
		setLastName,
		email,
		setEmail,
		address1,
		setAddress1,
		address2,
		setAddress2,
		country,
		setCountry,
		state,
		setState,
		city,
		setCity,
		zipCode,
		setZipCode,
		shippingFirstName,
		setShippingFirstName,
		shippingLastName,
		setShippingLastName,
		shippingAddress1,
		setShippingAddress1,
		shippingAddress2,
		setShippingAddress2,
		shippingCity,
		setShippingCity,
		shippingState,
		setShippingState,
		shippingCountry,
		setShippingCountry,
		shippingZip,
		setShippingZip,
		items,
		getItems,
		addItem,
		subtotal,
		setSubTotal,
		total,
		setTotal,
		fees,
		setFees,
		refundableBookingCost,
		setRefundableBookingCost,
		discountedAmount,
		setDiscountedAmount,
		installments,
		setInstallments,
		lastFourDigits,
		setLastFourDigits,
		createdAt,
		setCreatedAt,
		setEntireOrder,
		$reset,
	}
})
