import { useNuxtApp } from "#app"
import {
	HIDE_CART_TIMEOUT_WARNING,
	SHOW_CART_TIMEOUT_EXPIRED,
	SHOW_CART_TIMEOUT_WARNING,
} from "assets/events/cartTimeout"
import { useCartStore } from "~/stores/CartStore"
import { storeToRefs } from "pinia"
import { CART_CLEARED } from "assets/events/cart.js"

export default defineNuxtPlugin((nuxtApp) => {
	const { $eventBus } = useNuxtApp()
	const cartStore = useCartStore()
	const { expiresAt, hasShownExpiresWarning } = storeToRefs(cartStore)
	const runtimeConfig = useRuntimeConfig()

	const CART_TIMEOUT_EXPIRES_COOKIE = "CART_TIMEOUT_EXPIRES_COOKIE"
	const CART_TIMEOUT_WARNING_DELAY = runtimeConfig.public.CART_TIMEOUT_WARNING_MS
	let warningTimeoutId = null
	let expiresTimeoutId = null

	const getCookieExpiryDate = (cookieName) => {
		const cookieString = document.cookie
		const cookies = cookieString.split(";")

		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim()
			if (cookie.startsWith(`${cookieName}=`)) {
				const epoch = cookie.substring(cookieName.length + 1)
				return new Date(Number(epoch))
			}
		}

		return null
	}

	//Called whenever the expiresAt value is set in the CartStore
	const setCartTimeoutCookies = (newExpirationDate, oldExpirationDate) => {
		if (newExpirationDate && oldExpirationDate && newExpirationDate.getTime() === oldExpirationDate.getTime()) {
			//If no changes to expires at value, do nothing - this may happen when the cart is edited
			return
		}

		const currentDate = new Date()

		if (newExpirationDate && currentDate >= newExpirationDate) {
			//Clear cart data if the cart expiresAt was in the past.
			//This may happen if the tab is reopened or refreshed at a later date
			cartStore.$reset()
		}

		//Needed here when the cart is cleared (expiresAt set to null)
		deleteCartTimeoutCookies()

		//Set the cart timeout interrupts if the cart expires in the future
		if (currentDate < newExpirationDate) {
			document.cookie = `${CART_TIMEOUT_EXPIRES_COOKIE}=${newExpirationDate.getTime()};expires=${newExpirationDate.toUTCString()};path=/`
			setCartTimeoutInterrupts(newExpirationDate)
		}
	}

	const setCartTimeoutInterrupts = (cartExpirationDate) => {
		const currentDate = new Date()
		const currentTimeEpoch = currentDate.getTime()
		const cartExpiresEpoch = cartExpirationDate.getTime()

		const warningExpiryEpoch = cartExpiresEpoch - CART_TIMEOUT_WARNING_DELAY

		//Set the timeout warning epoch if it is in the future and if the user has chosen whether to extend
		if (warningExpiryEpoch > currentTimeEpoch && !hasShownExpiresWarning.value) {
			warningTimeoutId && clearTimeout(warningTimeoutId)
			const warningDelay = warningExpiryEpoch - currentTimeEpoch
			warningTimeoutId = setTimeout(cartTimeoutWarningCallback, warningDelay)
		}
		showWarningPopupIfNotShown(currentDate, cartExpirationDate)

		expiresTimeoutId && clearTimeout(expiresTimeoutId)
		const expiresDelay = cartExpiresEpoch - currentTimeEpoch
		expiresTimeoutId = setTimeout(cartTimeoutExpiredCallback, expiresDelay)
	}

	const cartTimeoutWarningCallback = () => {
		if (!hasShownExpiresWarning.value) {
			$eventBus.$emit(SHOW_CART_TIMEOUT_WARNING)
		}
	}

	const cartTimeoutExpiredCallback = () => {
		cartStore.$reset()
		$eventBus.$emit(CART_CLEARED)
		$eventBus.$emit(HIDE_CART_TIMEOUT_WARNING)
		$eventBus.$emit(SHOW_CART_TIMEOUT_EXPIRED)
	}

	//Deletes any callbacks and the cookie if they exist
	const deleteCartTimeoutCookies = () => {
		warningTimeoutId && clearTimeout(warningTimeoutId)
		expiresTimeoutId && clearTimeout(expiresTimeoutId)

		deleteCookieIfExists(CART_TIMEOUT_EXPIRES_COOKIE)
	}

	const deleteCookieIfExists = (cookieName) => {
		if (getCookieExpiryDate(cookieName)) {
			//Set the cookie expiry in the past to delete it
			const pastDate = new Date()
			pastDate.setTime(pastDate.getTime() - 1)
			document.cookie = `${cookieName}=;expires=${pastDate.toUTCString()};path=/`
		}
	}

	//Resets the cart timeout interrupts, this is for when the page is refreshed or the page is reopened at a later point
	const refreshCartTimeoutInterrupts = () => {
		const cartExpiresAt = expiresAt.value
		const currentDate = new Date()

		if (cartExpiresAt) {
			if (currentDate < cartExpiresAt) {
				setCartTimeoutInterrupts(cartExpiresAt)
			} else {
				//Clear cart data if the cart expiresAt was in the past
				cartStore.$reset()
				$eventBus.$emit(SHOW_CART_TIMEOUT_EXPIRED)
			}
		}
	}

	//Show the warning popup if a mobile user opens the tab with less than 5 minutes remaining
	const showWarningPopupIfNotShown = (currentDate, cartExpiresAt) => {
		const currentTimeEpoch = currentDate.getTime()
		const cartExpiresEpoch = cartExpiresAt.getTime()

		const warningExpiryEpoch = cartExpiresEpoch - CART_TIMEOUT_WARNING_DELAY

		if (currentTimeEpoch > warningExpiryEpoch && !hasShownExpiresWarning.value) {
			cartTimeoutWarningCallback()
		}
	}

	nuxtApp.provide("cartTimeout", {
		$init: () => {
			watch(expiresAt, setCartTimeoutCookies)

			nextTick(() => {
				//This event listener is necessary to have mobile devices refresh their timeouts when tabbing back to their browser
				document.addEventListener("visibilitychange", () => {
					if (document.visibilityState === "visible") {
						refreshCartTimeoutInterrupts()
					}
				})
			})
		},
	})
})
